var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"text-center"},[_vm._v(" Totale Candidati nella lista: "+_vm._s(_vm.items.length)+" ")]),(_vm.user['idUtente'] != 140 && _vm.gridType != 'formazione' && _vm.userCRMInfo.idRuolo != '2')?_c('div',{staticClass:"text-center"},[_c('v-checkbox',{attrs:{"label":"Mostra solo i miei contatti:"},model:{value:(_vm.filtroOperatore),callback:function ($$v) {_vm.filtroOperatore=$$v},expression:"filtroOperatore"}})],1):_vm._e(),_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"table-filter":{
      placeholder: 'Ricerca...',
      label: 'Ricerca:',
    },"sorter":"","hover":"","itemsPerPage":20,"pagination":"","striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{
      noItems: _vm.noItemsMessage,
    }},scopedSlots:_vm._u([{key:"tipologia",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center !important"}},[_c('div',[(item.tipologia === 'PF')?_c('i',{staticClass:"far fa-user"}):(item.tipologia === 'PG')?_c('i',{staticClass:"far fa-building"}):_vm._e()])])]}},{key:"data_ins",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.data_ins)))])]}},{key:"giorno_demo",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.giorno_demo))+" ore "+_vm._s(item.orario_demo))])]}},{key:"giorno_formazione",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_formazione))+" ore "+_vm._s(item.ora_formazione)+" ")])]}},{key:"avanzamento_corso",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.corso[0].avanzamento)+"% ")])]}},{key:"opzioni",fn:function(row){return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('Informazioni',{staticClass:"ml-2",attrs:{"itemId":row.item.id,"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid,"updateCandidato":_vm.getLista}}),_c('Note',{attrs:{"itemId":row.item.id,"candidato":row.item}}),_c('Log',{attrs:{"itemId":row.item.id,"candidato":row.item}})],1)])]}},{key:"actions",fn:function(row){return [((row.item.id_segnalatore != null &&
      row.item.id_referente == _vm.user.idUtente) ||
      row.item.id_segnalatore == null || _vm.user.idUtente == 140
      )?_c('td',[_c('div',{staticClass:"d-flex"},[(_vm.gridType === 'attivazione_account')?_c('Attivazione',{staticClass:"ml-2",attrs:{"itemId":row.item.id,"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid}}):_vm._e(),(row.item.id_step == 3 && _vm.gridType === 'webinar')?_c('Demo',{staticClass:"ml-2",attrs:{"itemId":row.item.id,"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid}}):_vm._e(),(_vm.gridType === 'registrazione_documentazione')?_c('ValidaDoc',{staticClass:"ml-2",attrs:{"itemId":row.item.id,"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid}}):_vm._e(),(_vm.gridType === 'primo_contatto' || _vm.gridType === 'social')?_c('Lavorazione',{staticClass:"ml-2",attrs:{"itemId":row.item.id,"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid,"updateCandidato":_vm.getLista}}):_vm._e(),(_vm.gridType != 'eliminati' && row.item.id_step != 10)?_c('Elimina',{attrs:{"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid}}):_vm._e(),(_vm.gridType == 'eliminati' || row.item.id_step == 10)?_c('Ripristina',{attrs:{"candidato":row.item,"step":_vm.step},on:{"aggiorna_grid":_vm.aggiorna_grid}}):_vm._e()],1)]):_c('td',{staticStyle:{"color":"#1f4b6b"}},[_c('strong',[_vm._v("Questo candidato è assegnato ad un'altro operatore")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }